import { configure } from '@mobi/ds'
import { ENV, SEGMENT_WRITE_KEY } from 'gatsby-env-variables'

// Utils
import analytics from '@mobi/libraries/analytics'
import feature from '@mobi/libraries/feature-toggle'
import Datadog from 'utils/datadog'
import trackings from 'utils/trackings'
import { getCookieBarStyles, setCookieBarStyles } from 'utils/cookieBar'

// Styles
import 'styles/main.scss'

console.log('Datadog.init: Started')
Datadog.init()
console.log('Datadog.init: Finished')

configure({
  originFonts: 'extern',
  fonts: {
    ItauText: [300, 400, 700, 900],
    ItauDisplay: [300, 700, 900]
  }
})

feature.setup({
  definitions: {
    dev: ENV === 'development',
    stg: ENV === 'staging',
    prod: ENV === 'production'
  },

  features: {
    isMockedRedirectPage: ['dev', 'stg']
  }
})

analytics.setup({
  segmentId: SEGMENT_WRITE_KEY,
  GTMId: 'GTM-KCDNHM8',
  trackings,
  isSDKTrackings: true
})

export const onRouteUpdate = () => {
  setCookieBarStyles(getCookieBarStyles())
}
