import jwtDecode from 'jwt-decode';

import { session } from 'utils/storage';

export const parseValuesToString = (obj) => {
  const cleanAndParsedObject = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) cleanAndParsedObject[key] = String(value);
  });
  return cleanAndParsedObject;
};

export const handleJwt = () => {
  const leadToken = session.getItem('leadToken');

  let proposalUUID = '';
  let cpf = '';
  let leadUUID = '';

  if (leadToken) {
    proposalUUID = jwtDecode(leadToken)?.oneclick_proposal_uuid || '';

    cpf = jwtDecode(leadToken)?.cid || '';

    leadUUID = jwtDecode(leadToken)?.lead_uuid || '';
  }

  return { proposalUUID, cpf, leadUUID };
};

export const mountLabel = (labels) => {
  return labels.reduce((prev, current) => `${prev}:${current}`);
};

export const isMobile = () => {
  if (typeof window === 'undefined') return false;

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const screenWidth = window.innerWidth;

  const isUserAgentMobile =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|opera mobi|nokie|windows phone|kindle|silk|mobile/i.test(
      userAgent.toLowerCase(),
    );
  const isScreenWidthMobile = screenWidth <= 768;

  return isUserAgentMobile || isScreenWidthMobile;
};
