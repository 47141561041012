const getParamFromUrl = (param) => {
  const queryString = window.location.search;

  return new URLSearchParams(queryString).get(param);
};

const getParamsFromUrl = (url) => {
  const newUrl = new URL(url);
  const urlParams = new URLSearchParams(newUrl.search);

  return Object.fromEntries(urlParams);
};

export default { getParamFromUrl, getParamsFromUrl };
