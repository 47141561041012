import safeStorage from '@mobi/utils/browser/safeStorage';

import helpers from 'utils/helpers';

const startSafeStorage = async () => {
  const anonymousId = helpers.session.getAnonymousId();
  return await safeStorage(`${anonymousId}`);
};

export default startSafeStorage;
