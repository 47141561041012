import { useEffect } from 'react'
import { session } from 'utils/storage'

const useLayout = () => {
  useEffect(() => {
    const hasNotSourceUrl = !session.getItem('source-url')

    if (hasNotSourceUrl) {
      session.setItem('source-url', window.location.href)
    }
  }, [])
}

export default useLayout