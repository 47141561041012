import { ENV } from 'gatsby-env-variables';

import { ga, segment } from '@mobi/libraries/analytics';
import gaSetup from '@mobi/libraries/analytics/ga/setup';

import helpers from 'utils/helpers';
import { session } from 'utils/storage';

import {
  handleJwt,
  isMobile,
  mountLabel,
  parseValuesToString,
} from './helpers';

const isDevelopment = ENV === 'development';

const setup = () => {
  return gaSetup({ isSDKTrackings: true }).then(
    () => {
      isDevelopment && console.log(`\x1b[32m GA4 - Script loaded \x1b[0m`);
    },
    () => {
      isDevelopment && console.error('GA4 - Script loading failed');
    },
  );
};

const pageview = {
  pageViewSegment: ({ pageName, eventName, ...extraData }) => {
    const flowName = session.getItem('flow-name');
    const { leadUUID, proposalUUID } = handleJwt();

    const payload = parseValuesToString({
      pageName,
      eventName,
      flowName,
      sourceLeadUUID: leadUUID,
      leadProposalUUID: proposalUUID,
      ...extraData,
      userScreen: isMobile() ? 'mobile' : 'desktop',
    });

    const metadata = {
      context: {
        clientSessionId: helpers.session.getClientSessionId(),
      },
    };

    return segment.page(pageName, payload, metadata);
  },

  pageViewGA: async ({ labels }) => {
    await setup();
    const nome = mountLabel(labels);
    const { cpf, proposalUUID, leadUUID } = handleJwt();

    const payload = {
      rule: 'pageLoad',
      page: {
        nome: `AC:NL:NCC:OneClickCartoes:${nome}`,
      },
      custom: {
        idproposta: proposalUUID,
      },
      visitor: {
        person_id: leadUUID,
        customerid: cpf,
      },
    };

    return ga.page(payload);
  },

  pageViewGA4: async ({ customPath }) => {
    await setup();
    const payload = {
      event_name: 'screen_view',
      parameters: {
        custom_path: `/conta/contratar/web-app/conquista/${customPath}`,
        implementation_team:
          'hub:onboarding-e-abertura-de-conta:contas-e-tarifas',
        flow: "abertura-de-conta-nao-logado",
      },
    };

    return window?.ItauDigitalAnalytics?.track({ analytics: payload });
  },
};

export default pageview;
