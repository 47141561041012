import CookieBar from 'components/CookieBar';

import './Layout.scss';
import useLayout from './useLayout';

const Layout = ({ children }) => {
  useLayout()

  return (
    <>
      <main className="layout">{children}</main>

      <CookieBar />
    </>
  );
};

export default Layout;
