import { ENV } from 'gatsby-env-variables';

import Storage from '@mobi/utils/browser/Storage';
import isClient from '@mobi/utils/browser/isClient';

const key = `storage-${ENV}`;
const storage = Storage({ prefix: key });

export const setItem = (key, value = '') => {
  session.setItem(key, JSON.stringify(value));
};

export const local = storage.local;
export const session = storage.session;

export const cookies = isClient()
  ? document.cookie.split('; ').reduce((initialState, param) => {
      const [name, value] = param.split('=');

      return {
        ...initialState,
        [name]: value,
      };
    }, {})
  : {};

/* Methods
  getItem(item)
  setItem(name, value)
  getLength()
  removeItem(name),
  clearWithPrefix(),
  clear(),
*/
