const scrollToElement = (elementId, yOffset = 0) => {
  const element = document.getElementById(elementId);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};

export default {
  scrollToElement,
};
