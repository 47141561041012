import isClient from '@mobi/utils/browser/isClient';

import helpers from 'utils/helpers';
import { cookies, local, session } from 'utils/storage';
import safeStorage from 'utils/storage/safeStorage';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

const getSessionId = () => {
  const sid = session.getItem('sessionId') || guid();
  session.setItem('sessionId', sid);
  return sid;
};

const getAnonymousId = () => {
  const sid = local.getItem('anonymousId') || guid();
  local.setItem('anonymousId', sid);
  return sid;
};

const getGoogleClientId = () => {
  const googleClientId = cookies._ga || guid();
  return googleClientId;
};

const getGoogleClickId = () => {
  if (!isClient()) return null;

  return helpers.url.getParamFromUrl('gclid');
};

const getFacebookClickId = () => {
  if (!isClient()) return null;

  return helpers.url.getParamFromUrl('fbclid');
};

const getTiktokClickId = () => {
  if (!isClient()) return null;

  return helpers.url.getParamFromUrl('ttclid');
};

const getClientSessionId = () => {
  const now = new Date().getTime();

  const { value, expiration } = session.getItem('_sessionId') || {};

  // returns the current sessionId only if it not expired
  if (value && expiration > now) return value;

  // 4 hours as ms -> hours * minutes * seconds * milliseconds
  const duration = 4 * 60 * 60 * 1000;
  const newId = { value: guid(), expiration: now + duration };

  session.setItem('_sessionId', newId);

  return newId.value;
};

const getSourceUrl = () => {
  if (!isClient()) return null;

  return session.getItem('source-url');
};

const getReferrerUrl = () => {
  if (!isClient()) return null;

  return document.referrer;
};

const hasProposalInProgress = async () => {
  const cryptoStore = await safeStorage();

  const hasEnrichedLeadToken = await cryptoStore?.get(
    'has-enriched-lead-token',
  );
  const sessionTokenExpTime = await cryptoStore?.get('session-token-exp');

  const now = Math.floor(Date.now() / 1000);

  const isNotTokenExpired = now < Number(sessionTokenExpTime);

  const hasProposal = hasEnrichedLeadToken && isNotTokenExpired;

  return hasProposal;
};

const clearProposalInProgress = async () => {
  const cryptoStore = await safeStorage();
  const sourceUrl = getSourceUrl()
  session.clear();

  session.setItem('source-url', sourceUrl)
  await cryptoStore?.clear();
};

export default {
  guid,
  getSourceUrl,
  getReferrerUrl,
  getSessionId,
  getAnonymousId,
  getGoogleClientId,
  getGoogleClickId,
  getFacebookClickId,
  getTiktokClickId,
  hasProposalInProgress,
  getClientSessionId,
  clearProposalInProgress,
};
