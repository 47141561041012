import { datadogRum } from '@datadog/browser-rum';
import {
  DD_ALLOWED_URLS,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_PROXY,
  ENV,
  DD_SESSION_SAMPLE_RATE,
  GIT_TAG_VERSION
} from 'gatsby-env-variables'

const init = () => {
  const environment = ENV === 'production' ? 'prod' : ENV;

  datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    allowedTracingUrls: DD_ALLOWED_URLS,
    site: 'datadoghq.com',
    service: 'jn6_contas_www',
    env: environment,
    proxy: DD_PROXY,
    version: GIT_TAG_VERSION,
    sessionSampleRate: DD_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export default {
  init,
};
