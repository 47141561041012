const validCharRegex = /[ªº$&+,:;=?@#|'<>.^*()%_!-]/gi;

const removeSpecialChars = (str = '') => {
  if (!str) return str;

  const newStr = str.replace(validCharRegex, '');
  return newStr;
};

const removeEmptySpacesString = (str = '') => str.replaceAll(' ', '');

const removeSpecialAndEmptyChars = (str = '') => {
  return removeSpecialChars(removeEmptySpacesString(str));
};

const getLastCharacters = (str = '', charactersNumber) => {
  return str.slice(-charactersNumber);
};

const getFirstWord = (str = '') => {
  return str.split(' ')?.[0];
};

export default {
  removeSpecialChars,
  removeEmptySpacesString,
  removeSpecialAndEmptyChars,
  getLastCharacters,
  getFirstWord,
};
