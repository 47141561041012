const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const isObjectNotEmpty = (obj) => {
  return Object.keys(obj).length > 0;
};

export default {
  isObjectEmpty,
  isObjectNotEmpty,
};
