import { COOKIEBAR_MFE_URL } from 'gatsby-env-variables';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import {
  getCookieBarStyles,
  saveCookieBarStylesToStorage,
} from 'utils/cookieBar';

const CookieBar = () => {
  const [styles, setStyles] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.saveCookieBarStylesToStorage = saveCookieBarStylesToStorage;
    setIsLoaded(true);
    setStyles(getCookieBarStyles());
  }, []);

  return isLoaded ? (
    <>
      <Helmet>
        <link rel="stylesheet" href={`${COOKIEBAR_MFE_URL}/styles.css`} />
        <script src={`${COOKIEBAR_MFE_URL}/runtime.js`}></script>
        <script
          src={`${COOKIEBAR_MFE_URL}/main.js`}
          onLoad={`saveCookieBarStylesToStorage()`}
        ></script>
        {styles && <style id="banner-cookies-internal">{styles}</style>}
      </Helmet>
      <itau-cookie-consent-banner segment="varejo"></itau-cookie-consent-banner>
    </>
  ) : null;
};

export default CookieBar;
