import safeStorage from 'utils/storage/safeStorage';

const storeEnrichedLeadToken = async ({
  enrichedLeadToken,
  enrichedLeadTokenExp,
}) => {
  const cryptoStore = await safeStorage();

  await cryptoStore?.set('session-token', enrichedLeadToken);
  await cryptoStore?.set('session-token-exp', enrichedLeadTokenExp);
  await cryptoStore?.set('has-enriched-lead-token', true);
};

export default {
  storeEnrichedLeadToken,
};
