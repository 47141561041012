import interactions from './interactions.js';

let lastLoggedInterval = null;
let lastScrollPosition = typeof window !== 'undefined' ? window.pageYOffset : 0;

const scrollPercentIntervals = {
  10: '10%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
};

export function trackScrolling(pageName) {
  const scrollTop = window.pageYOffset;
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;
  const scrollPercent = Math.round(
    (scrollTop / (scrollHeight - clientHeight)) * 100,
  );

  const keys = Object.keys(scrollPercentIntervals);
  let currentInterval = null;
  for (let i = 0; i < keys.length; i++) {
    if (scrollPercent < keys[i]) {
      currentInterval = scrollPercentIntervals[keys[i]];
      break;
    }
  }

  if (currentInterval !== null && currentInterval !== lastLoggedInterval) {
    const scrollDirection =
      window.pageYOffset > lastScrollPosition ? 'down' : 'up';
    const payload = {
      pageName,
      eventName: `Scrolled ${scrollDirection} ${currentInterval}`,
    };

    interactions.pageInteraction(payload);
    lastLoggedInterval = currentInterval;
  }

  lastScrollPosition = window.pageYOffset;
}
